import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { format } from 'date-fns';
import Header from './Header';

const supabase = createClient(
  'https://lllqncjwqcbrygqujdin.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxsbHFuY2p3cWNicnlncXVqZGluIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg3MjAxNzMsImV4cCI6MjA1NDI5NjE3M30.Un4XIFBnq1rOrSn484onaCHV0a_mTUYKRb-SVbhKXJQ'
);

const ArticleView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const fetchArticle = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('news')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      setArticle(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchArticle();
  }, [fetchArticle]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: article.title,
          text: article.title,
          url: window.location.href,
        });
      } catch (err) {
        console.log('Error sharing:', err);
      }
    }
  };

  const handleTwitterShare = () => {
    const text = encodeURIComponent(article.title);
    const url = encodeURIComponent(window.location.href);
    window.open(
      `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
      '_blank'
    );
  };

  const handleFacebookShare = () => {
    const url = encodeURIComponent(window.location.href);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      '_blank'
    );
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setShowCopyNotification(true);
      setTimeout(() => setShowCopyNotification(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex-grow flex items-center justify-center">
          <div className="text-xl">Loading article...</div>
        </div>
      </div>
    );
  }

  if (error || !article) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex-grow flex items-center justify-center">
          <div className="text-xl text-red-600">
            Error: {error || 'Article not found'}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-grow bg-gray-50">
        <div className="container mx-auto px-4 py-12">
          <button
            onClick={() => navigate('/news')}
            className="mb-8 flex items-center text-blue-600 hover:text-blue-800"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            Back to News
          </button>

          <article className="bg-white rounded-lg shadow-lg overflow-hidden">
            {article.image_url && (
              <div className="w-full h-96 relative">
                <img
                  src={article.image_url}
                  alt={article.title}
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            
            <div className="p-8">
              <div className="flex items-center justify-between mb-6">
                <span className="px-4 py-2 bg-gray-100 text-gray-700 rounded-full">
                  {article.category}
                </span>
                <time className="text-gray-500">
                  {format(new Date(article.created_at), 'MMMM dd, yyyy')}
                </time>
              </div>

              <h1 className="text-4xl font-bold mb-6">{article.title}</h1>

              

              <div className="prose prose-lg max-w-none">
  {article.content
    .split(/(?:<div><br><\/div>|<div><\/div>)/)
    .filter(paragraph => paragraph.trim() !== '')
    .map((paragraph, index) => (
      <p 
        key={index} 
        className="mb-4 text-justify"  // Added text-justify class
        dangerouslySetInnerHTML={{ 
          __html: paragraph
            .replace(/<div>/g, '')
            .replace(/<\/div>/g, '')
            .replace(/<br>/g, '\n')
            .trim() 
        }}
        style={{ 
          whiteSpace: 'pre-line',
          textAlign: 'justify',  // Added for better browser compatibility
          hyphens: 'auto'        // Added to help with word breaks in justified text
        }}
      />
    ))}
</div>

              {/* Share buttons with working functionality */}
              <div className="mt-12 pt-8 border-t border-gray-200">
                <h3 className="text-lg font-semibold mb-4">Share this article</h3>
                <div className="flex flex-wrap gap-4">
                  {navigator.share && (
                    <button
                      onClick={handleShare}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    >
                      Share
                    </button>
                  )}
                  <button
                    onClick={handleTwitterShare}
                    className="px-4 py-2 bg-[#1DA1F2] text-white rounded-lg hover:bg-[#1a8cd8]"
                  >
                    Share on Twitter
                  </button>
                  <button
                    onClick={handleFacebookShare}
                    className="px-4 py-2 bg-[#4267B2] text-white rounded-lg hover:bg-[#365899]"
                  >
                    Share on Facebook
                  </button>
                  <button
                    onClick={handleCopyLink}
                    className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
                  >
                    Copy Link
                  </button>
                </div>
                {showCopyNotification && (
                  <div className="mt-4 text-green-600">
                    Link copied to clipboard!
                  </div>
                )}
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default ArticleView;