import React, { useRef, useState } from 'react';
import Header from './Header';
import ProductShowcase from './ProductShowcase';
import OurWork from './OurWork';

const ServiceList = () => (
  <div className="flex justify-between py-4 px-6 border-b border-gray-800 bg-black text-white">
    <div className="text-sm">Yacht Experiences<br/>Aerial Luxury Views</div>
    <div className="text-sm">Wedding Villas<br/>Perfect Moments</div>
    <div className="text-sm">Special Events<br/>Island Celebrations</div>
    <div className="text-sm">Property Showcase<br/>Luxury Real Estate</div>
  </div>
);

const MainContent = ({ scrollToServices }) => (
  <div className="flex flex-col justify-center items-center h-screen text-white relative z-10">
    <h1 className="text-6xl font-bold text-center leading-tight text-shadow-lg">
      Elevate Your<br/>
      Saint Barth Experience
    </h1>
    <p className="text-xl mt-4 text-center max-w-2xl text-shadow-md">
      Premium drone services for luxury yachts, exclusive villas, and special events on the island
    </p>
    <div className="mt-8 cursor-pointer" onClick={scrollToServices}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
      </svg>
    </div>
  </div>
);

// Full Screen Drone Image Section
const DroneImageSection = () => (
  <div className="w-full h-screen relative">
    <img 
      src="https://lllqncjwqcbrygqujdin.supabase.co/storage/v1/object/public/media//done_stbarth.png" 
      alt="Aerial Drone Photography" 
      className="w-full h-full object-cover"
    />
    <div className="absolute inset-0 bg-black/30 flex items-center justify-center">
      <div className="text-center text-white">
        <h2 className="text-5xl font-bold mb-4 text-shadow-lg">Premium Aerial Photography</h2>
        <p className="text-xl max-w-3xl mx-auto text-shadow-md">Capturing Saint Barth's breathtaking beauty from above</p>
      </div>
    </div>
  </div>
);

const ServiceCard = ({ title, description, icon }) => (
  <div className="bg-black p-6 rounded-lg shadow-lg text-white border border-gray-800">
    <div className="text-3xl mb-3">{icon}</div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

const Services = () => (
  <div className="py-16 bg-black">
    <div className="container mx-auto px-4">
      <h2 className="text-4xl font-bold text-center mb-12 text-white">Exclusive Drone Services in Saint Barth</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <ServiceCard 
          title="Yacht Experiences" 
          description="Capture breathtaking aerial footage of your luxury yacht experience in the crystalline waters of Saint Barthélemy." 
          icon="⚓" 
        />
        <ServiceCard 
          title="Villa Photography" 
          description="Showcase your luxury villa with stunning aerial perspectives highlighting the property and its Caribbean surroundings." 
          icon="🏝️" 
        />
        <ServiceCard 
          title="Wedding Coverage" 
          description="Preserve your special day in paradise with spectacular aerial footage of your wedding celebration in Saint Barth." 
          icon="💍" 
        />
        <ServiceCard 
          title="Special Events" 
          description="Document exclusive island parties, celebrations, and corporate events from a unique aerial perspective." 
          icon="🎉" 
        />
        <ServiceCard 
          title="Luxury Real Estate" 
          description="Elevate your property listings with professional aerial photography showcasing the island's most exclusive estates." 
          icon="🏘️" 
        />
        <ServiceCard 
          title="Island Exploration" 
          description="Discover hidden coves, pristine beaches, and the natural beauty of Saint Barthélemy from above." 
          icon="🌴" 
        />
      </div>
    </div>
  </div>
);

const CallToAction = () => (
  <div className="py-16 bg-black text-white text-center border-t border-gray-800">
    <div className="container mx-auto px-4">
      <h2 className="text-4xl font-bold mb-4">Experience Saint Barth from Above</h2>
      <p className="text-xl mb-8 max-w-2xl mx-auto">
        Elevate your island experience with our professional drone services, capturing the beauty of Saint Barthélemy's pristine beaches, luxury yachts, and exclusive events.
      </p>
      <a href="/contact" className="inline-block bg-white text-black px-8 py-3 rounded text-lg font-bold hover:bg-gray-200 transition-colors">
        Book Now
      </a>
    </div>
  </div>
);

// Add custom text shadow styles
const customStyles = `
  .text-shadow-lg {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
  }
  .text-shadow-md {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.75);
  }
`;

const ServicesPage = () => {
  const servicesRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const scrollToServices = () => {
    servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Add custom styles */}
      <style>{customStyles}</style>
      
      <Header scrollToOurWork={scrollToServices} />
      
      {/* Hero Section with Video Background */}
      <div className="relative h-screen">
        {/* Video Background - only in the hero section */}
        <div className="absolute top-0 left-0 w-full h-full z-0 overflow-hidden">
          {/* Video Overlay - Adjust opacity here */}
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/70 z-10"></div>
          
          {/* Light filter for better text visibility */}
          <div className={`absolute inset-0 backdrop-blur-sm ${isVideoLoaded ? 'opacity-30' : 'opacity-100'} transition-opacity duration-1000 z-0`}></div>
          
          {/* The video */}
          <video 
            className="object-cover w-full h-full"
            autoPlay 
            loop 
            muted 
            playsInline
            onLoadedData={() => setIsVideoLoaded(true)}
          >
            <source src="https://lllqncjwqcbrygqujdin.supabase.co/storage/v1/object/public/media//dji_fly_20250318_175756_20_1742337286310_video_cache%20(online-video-cutter.com).mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        
        {/* Main Content Overlay */}
        <div className="relative z-20 h-full">
          <div className="pt-16"> {/* Add padding-top to account for fixed header */}
            <MainContent scrollToServices={scrollToServices} />
          </div>
        </div>
      </div>
      
      {/* Rest of the page content */}
      <ServiceList />
      
            <div ref={servicesRef}>
        <Services />
      </div>
      
      {/* Full-screen drone image between Services and Call to Action */}
      <DroneImageSection />
      
      <CallToAction />
      
      {/* Footer */}
      {/* Footer removed as requested */}
    </div>
  );
};

export default ServicesPage;