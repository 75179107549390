import React, { useState, useRef, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import AdminHeader from './AdminHeader';

const supabase = createClient(
  'https://lllqncjwqcbrygqujdin.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxsbHFuY2p3cWNicnlncXVqZGluIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg3MjAxNzMsImV4cCI6MjA1NDI5NjE3M30.Un4XIFBnq1rOrSn484onaCHV0a_mTUYKRb-SVbhKXJQ'
);

const AddNewsForm = () => {
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category: '',
    image_url: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFormat = (command, value = null) => {
    document.execCommand(command, false, value);
    contentRef.current.focus();
  };

  // Save current cursor position
  const saveSelection = () => {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        return sel.getRangeAt(0);
      }
    }
    return null;
  };

  // Restore cursor position
  const restoreSelection = (range) => {
    if (range && window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const handleEditorInput = (e) => {
    const content = e.target.innerHTML
      .replace(/<div><br><\/div>/g, '\n\n')  // Replace empty divs with double newlines
      .replace(/<div>/g, '')                  // Remove opening div tags
      .replace(/<\/div>/g, '\n')             // Replace closing div tags with newlines
      .replace(/<br>/g, '\n')                // Replace br tags with newlines
      .replace(/\n{3,}/g, '\n\n')            // Normalize multiple newlines to maximum of 2
      .trim();                               // Remove leading/trailing whitespace
      
    setFormData(prev => ({ ...prev, content }));
  };
  

  const handleInlineImageUpload = async (file) => {
    try {
      const timestamp = Date.now();
      const fileExt = file.name.split('.').pop();
      const fileName = `inline-${timestamp}-${Math.random().toString(36).substring(2)}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('news-images')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) throw uploadError;

      const { data: urlData } = supabase.storage
        .from('news-images')
        .getPublicUrl(fileName);

      return urlData.publicUrl;
    } catch (error) {
      console.error('Error uploading inline image:', error);
      throw error;
    }
  };

  const handlePaste = async (e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        e.preventDefault();
        const file = item.getAsFile();
        try {
          const imageUrl = await handleInlineImageUpload(file);
          const range = saveSelection();
          document.execCommand('insertImage', false, imageUrl);
          restoreSelection(range);
        } catch (error) {
          console.error('Error handling pasted image:', error);
        }
      }
    }
  };

  // Rest of your existing functions remain the same
  const handleImageChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setMessage('Image file is too large. Maximum size is 5MB.');
        return;
      }
      
      if (!file.type.startsWith('image/')) {
        setMessage('Please select a valid image file.');
        return;
      }
      
      setImageFile(file);
      setMessage('');
    }
  };

  const uploadImage = async (file) => {
    try {
      const timestamp = Date.now();
      const fileExt = file.name.split('.').pop();
      const fileName = `${timestamp}-${Math.random().toString(36).substring(2)}.${fileExt}`;
      const filePath = `${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('news-images')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) throw uploadError;

      const { data: urlData } = supabase.storage
        .from('news-images')
        .getPublicUrl(filePath);

      if (!urlData?.publicUrl) {
        throw new Error('Failed to get public URL');
      }

      return urlData.publicUrl;
    } catch (error) {
      console.error('Image upload error:', error);
      throw new Error(error.message || 'Failed to upload image');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setUploadProgress(0);

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) {
        throw new Error('Authentication required');
      }

      let imageUrl = formData.image_url;
      if (imageFile) {
        try {
          setUploadProgress(20);
          imageUrl = await uploadImage(imageFile);
          setUploadProgress(100);
        } catch (uploadError) {
          setMessage(uploadError.message);
          setLoading(false);
          return;
        }
      }

      const insertData = {
        ...formData,
        image_url: imageUrl,
        created_at: new Date().toISOString(),
        user_id: session.user.id
      };

      const { data, error } = await supabase
        .from('news')
        .insert([insertData])
        .select()
        .single();

      if (error) {
        throw error;
      }

      setMessage('News post created successfully!');
      setFormData({
        title: '',
        content: '',
        category: '',
        image_url: '',
      });
      contentRef.current.innerHTML = '';
      setImageFile(null);
      setUploadProgress(0);

    } catch (error) {
      console.error('Operation failed:', error);
      setMessage(error.message || 'Failed to create news post');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <AdminHeader />
      <div className="flex-grow bg-gray-50 py-12">
        <div className="max-w-2xl mx-auto px-4">
          <h1 className="text-3xl font-bold mb-8">Add New Post</h1>
          
          <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Category
              </label>
              <input
                type="text"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Content
              </label>
              <div className="mb-2 flex space-x-2">
                <button
                  type="button"
                  onClick={() => handleFormat('bold')}
                  className="px-2 py-1 border rounded hover:bg-gray-100"
                >
                  B
                </button>
                <button
                  type="button"
                  onClick={() => handleFormat('italic')}
                  className="px-2 py-1 border rounded hover:bg-gray-100"
                >
                  I
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const url = prompt('Enter image URL:');
                    if (url) {
                      const range = saveSelection();
                      handleFormat('insertImage', url);
                      restoreSelection(range);
                    }
                  }}
                  className="px-2 py-1 border rounded hover:bg-gray-100"
                >
                  Image
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const url = prompt('Enter link URL:');
                    if (url) {
                      const range = saveSelection();
                      handleFormat('createLink', url);
                      restoreSelection(range);
                    }
                  }}
                  className="px-2 py-1 border rounded hover:bg-gray-100"
                >
                  Link
                </button>
              </div>
              <div
  ref={contentRef}
  contentEditable
  onInput={handleEditorInput}
  onPaste={handlePaste}
  className="w-full min-h-[200px] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-justify"
  style={{ 
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    lineHeight: '1.5',
    textAlign: 'justify',
    hyphens: 'auto'
  }}
/>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Featured Image
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {uploadProgress > 0 && uploadProgress < 100 && (
                <div className="mt-2">
                  <div className="h-2 bg-gray-200 rounded-full">
                    <div 
                      className="h-2 bg-blue-600 rounded-full" 
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                disabled={loading}
                className={`px-4 py-2 rounded-md text-white ${
                  loading 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-blue-600 hover:bg-blue-700'
                }`}
              >
                {loading ? 'Creating...' : 'Create Post'}
              </button>

              {message && (
                <p className={`text-sm ${
                  message.startsWith('Error') || message.includes('failed')
                    ? 'text-red-600' 
                    : 'text-green-600'
                }`}>
                  {message}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewsForm;